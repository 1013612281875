.leftMargin {
    margin-top: 5%;
    margin-left: 25%;
    margin-right: 5%;
}
@media (max-width: 767px) {
    .leftMargin {
        margin-left: 2%;
        margin-right: 2%;
    }
}
@media (max-width: 400px) {
    .leftMargin {
        margin-left: 0%;
        margin-right: 0%;
    }
}



.example {
    margin-top: 40px;
}


.decoration {
    padding: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: large;
}