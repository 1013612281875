.App {
  text-align: center;
}


.example {
    margin-top: 40px;
}


.step {
    background-color: #f2f9ff;
    min-height: 60px;
    padding: 10px;
}


.footer-buttons {
    margin-top: 10px;
}



.circlered {
    color: #d9534f;
}

.circlegreen {
    color: #3c763d;
}

.circlebold {
    font-weight: bold;
}


.leftMargin {
    margin-left: 23%;
    margin-right: 5%;
}
@media (max-width: 767px) {
    .leftMargin {
        margin-left: 5%;
        margin-right: 2%;
    }
}
@media (max-width: 300px) {
    .leftMargin {
        margin-left: 20px;
        margin-right: 20px;
    }
}




.circleRed {
    background: red;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}

.circleGrey {
    background: #cccccc;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}

.circleGreen {
    background: #5EA226;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #2d4a14;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}

.circleBlue {
    background: #5178D0;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}

.circlePink {
    background: #EF0BD8;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}




@media (max-width: 767px) {
    .circleRed {
        background: red;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleGrey {
        background: #cccccc;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #fff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleGreen {
        background: #5EA226;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #2d4a14;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleBlue {
        background: #5178D0;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circlePink {
        background: #EF0BD8;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

}
@media (max-width: 300px) {
    .circleRed {
        background: red;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleGrey {
        background: #cccccc;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #fff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleGreen {
        background: #5EA226;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #2d4a14;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleBlue {
        background: #5178D0;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circlePink {
        background: #EF0BD8;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

}
.dnRadio{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(77,98,108,.5);
}

.boxGreen{
    background-color: lightgrey;
    width: 300px;
    border: 25px solid green;
    padding: 25px;
    margin: 25px;
}


.productRedBox{
    box-sizing: content-box;
    padding: 30px;
    margin-top: 20px;
    border: 2px solid red;
}

.productBlackBox{
    box-sizing: content-box;
    padding: 30px;
    margin-top: 20px;
    border: 2px solid black;
}