.fieldIcon {
    float: right;
    margin-left: -25px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
}

.container{
    padding-top:50px;
    margin: auto;
}

.decoration {
    padding: 5px 0 0 30px;
    font-style: normal;
    font-weight: bold;
    font-size: x-large;
}

@media (max-width: 360px) {
    .decoration {
        font-style: normal;
        font-weight: bold;
        font-size: x-large;
    }
}