body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}


.example {
    margin-top: 40px;
}


.step {
    background-color: #f2f9ff;
    min-height: 60px;
    padding: 10px;
}


.footer-buttons {
    margin-top: 10px;
}



.circlered {
    color: #d9534f;
}

.circlegreen {
    color: #3c763d;
}

.circlebold {
    font-weight: bold;
}


.leftMargin {
    margin-left: 23%;
    margin-right: 5%;
}
@media (max-width: 767px) {
    .leftMargin {
        margin-left: 5%;
        margin-right: 2%;
    }
}
@media (max-width: 300px) {
    .leftMargin {
        margin-left: 20px;
        margin-right: 20px;
    }
}




.circleRed {
    background: red;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}

.circleGrey {
    background: #cccccc;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}

.circleGreen {
    background: #5EA226;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #2d4a14;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}

.circleBlue {
    background: #5178D0;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}

.circlePink {
    background: #EF0BD8;
    border-radius: 2em;
    -moz-border-radius: 2em;
    -webkit-border-radius: 2em;
    color: #ffffff;
    display: inline-block;
    font-weight: bold;
    line-height: 3.5em;
    margin-right: 55px;
    text-align: center;
    width: 3.5em;
}




@media (max-width: 767px) {
    .circleRed {
        background: red;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleGrey {
        background: #cccccc;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #fff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleGreen {
        background: #5EA226;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #2d4a14;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleBlue {
        background: #5178D0;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circlePink {
        background: #EF0BD8;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

}
@media (max-width: 300px) {
    .circleRed {
        background: red;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleGrey {
        background: #cccccc;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #fff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleGreen {
        background: #5EA226;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #2d4a14;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circleBlue {
        background: #5178D0;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

    .circlePink {
        background: #EF0BD8;
        border-radius: 2em;
        -moz-border-radius: 2em;
        -webkit-border-radius: 2em;
        color: #ffffff;
        display: inline-block;
        font-weight: bold;
        line-height: 3.5em;
        margin-right: 15px;
        margin-left: 70px;
        margin-bottom: 10px;
        text-align: center;
        width: 3.5em;
    }

}
.dnRadio{
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border: 1px solid rgba(77,98,108,.5);
}

.boxGreen{
    background-color: lightgrey;
    width: 300px;
    border: 25px solid green;
    padding: 25px;
    margin: 25px;
}


.productRedBox{
    box-sizing: content-box;
    padding: 30px;
    margin-top: 20px;
    border: 2px solid red;
}

.productBlackBox{
    box-sizing: content-box;
    padding: 30px;
    margin-top: 20px;
    border: 2px solid black;
}
.Modal {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}
.ModalImage {
    position: fixed;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: 1px solid #ccc;
    box-shadow: 1px 1px 1px black;
    padding: 16px;
    left: 15%;
    top: 30%;
    box-sizing: border-box;
    transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
    .Modal {
        width: 500px;
        left: calc(50% - 250px);
    }
}
@media (min-width: 600px) {
    .ModalImage {
        width: 700px;
        left: calc(50% - 250px);
    }
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
}
.fieldIcon {
    float: right;
    margin-left: -25px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
}

.container{
    padding-top:50px;
    margin: auto;
}

.decoration {
    padding: 5px 0 0 30px;
    font-style: normal;
    font-weight: bold;
    font-size: x-large;
}

@media (max-width: 360px) {
    .decoration {
        font-style: normal;
        font-weight: bold;
        font-size: x-large;
    }
}
.fieldIcon {
    float: right;
    margin-left: -25px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
}

.container{
    padding-top:50px;
    margin: auto;
}
.fieldIcon {
    float: right;
    margin-left: -25px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
}

.container{
    padding-top:50px;
    margin: auto;
}

.fieldIcon {
    float: right;
    margin-left: -25px;
    margin-top: -40px;
    position: relative;
    z-index: 2;
}

.container{
    padding-top:50px;
    margin: auto;
}

.decoration {
    padding: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: large;
}
.leftMargin {
    margin-top: 5%;
    margin-left: 25%;
    margin-right: 5%;
}
@media (max-width: 767px) {
    .leftMargin {
        margin-left: 2%;
        margin-right: 2%;
    }
}
@media (max-width: 400px) {
    .leftMargin {
        margin-left: 0%;
        margin-right: 0%;
    }
}



.example {
    margin-top: 40px;
}


.decoration {
    padding: 5px;
    font-style: normal;
    font-weight: bold;
    font-size: large;
}
.example1 {
    height: 50px;
/*    overflow: hidden;
    position: relative;*/
}
.example1 h3 {
    font-size: 1em;
    color: limegreen;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    /* Starting position */
    transform:translateX(100%);
    /* Apply animation to this element */
    animation: example1 25s linear infinite;
    white-space: nowrap;
    overflow: hidden;
}
/* Move it (define the animation) */
@keyframes example1 {
    0%   { /* Firefox bug fix */ /* Firefox bug fix */
        transform: translateX(100%);
    }
    100% { /* Firefox bug fix */ /* Firefox bug fix */
        transform: translateX(-100%);
    }
}





.botHeader{
    background-color: #292c2f;
    box-shadow: 0 1px 1px #ccc;
    padding: 10px 10px;
    height: 60px;
    color: #ffffff;
    box-sizing: border-box;
    top: -100px;
    transition: top 0.3s;
}
