.example1 {
    height: 50px;
/*    overflow: hidden;
    position: relative;*/
}
.example1 h3 {
    font-size: 1em;
    color: limegreen;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    line-height: 50px;
    text-align: center;
    /* Starting position */
    -moz-transform:translateX(100%);
    -webkit-transform:translateX(100%);
    transform:translateX(100%);
    /* Apply animation to this element */
    -moz-animation: example1 25s linear infinite;
    -webkit-animation: example1 25s linear infinite;
    animation: example1 25s linear infinite;
    white-space: nowrap;
    overflow: hidden;
}
/* Move it (define the animation) */
@-moz-keyframes example1 {
    0%   { -moz-transform: translateX(100%); }
    100% { -moz-transform: translateX(-100%); }
}
@-webkit-keyframes example1 {
    0%   { -webkit-transform: translateX(100%); }
    100% { -webkit-transform: translateX(-100%); }
}
@keyframes example1 {
    0%   {
        -moz-transform: translateX(100%); /* Firefox bug fix */
        -webkit-transform: translateX(100%); /* Firefox bug fix */
        transform: translateX(100%);
    }
    100% {
        -moz-transform: translateX(-100%); /* Firefox bug fix */
        -webkit-transform: translateX(-100%); /* Firefox bug fix */
        transform: translateX(-100%);
    }
}





.botHeader{
    background-color: #292c2f;
    box-shadow: 0 1px 1px #ccc;
    padding: 10px 10px;
    height: 60px;
    color: #ffffff;
    box-sizing: border-box;
    top: -100px;
    -webkit-transition: top 0.3s;
    transition: top 0.3s;
}